import { Table, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";

const Inventory = () => {
  const [data, setData] = useState([]);
  const [select, setSelect] = useState(false);
  const updateHandler = async (phone, status) => {
    let body = { status, phone };
    let update = await axios.patch(
      `https://cftlabs-admin.herokuapp.com/contact/update-status`,
      body
    );
  };
  let getData = async () => {
    let get = await axios.get(
      "https://cftlabs-admin.herokuapp.com/contact/get-leads"
    );
    setData(get.data.reverse());
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="d-flex flex-column align-items-center mt-5">
      <div className="table-wid w-75">
        <div
          className="rounded-top p-3 bg-dark"
        >
          <span className="text-light fw-bold">Leads</span>
        </div>
        <Table className="table-dark table-wid" striped bordered hover>
          <thead>
            <tr>
              <th className="id">ID</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Date</th>
              <th>Service</th>
              <th>Message</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={item._id}>
                  <td className="id">{index+1}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  <td>{item.date}</td>
                  <td>{item.service}</td>
                  <td style={{wordBreak: "break-all"}}>{item.message}</td>
                  <td className="dropdown d-flex justify-content-around align-items-center">
                    <select
                      value={item.status}
                      onChange={(e) => {item.status = e.target.value; setSelect(!select);}}
                    >
                      <option value="pending">Pending</option>
                      <option value="Contacted">Contacted</option>
                    </select>
                    <button
                      onClick={() =>
                        updateHandler(item.phone, item.status)
                      }
                    >
                      Update
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
export default Inventory;
