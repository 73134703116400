import axios from 'axios';

const deleteWorkApi = (workId) => {
	console.log('work is : ', workId);
	return axios.delete(
		'https://cftlabs-admin.herokuapp.com/work/delete-work',
		{ data: { workId } }
	);
};

export default deleteWorkApi;
