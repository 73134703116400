import {
	Box,
	Text,
	TableContainer,
	Th,
	Tr,
	Tbody,
	Thead,
	Table,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Career from './Career';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import getJobsApi from '../../../apis/dashboard/career/getJobsApi';

const Careers = () => {
	const navigate = useNavigate();
	const [dataList, setDataList] = useState([]);
	const [state, setState] = useState(false);

	useEffect(() => {
		const getList = async () => {
			try {
				const response = await getJobsApi();
				setDataList(response.data);
			} catch (error) {}
		};

		getList();
	}, [state]);

	return (
		<>
			<Box
				w={{ base: '100%', lg: '86vw' }}
				h={'98%'}
				bg='white'
				mx={{ base: 1, lg: '10px' }}
				borderRadius='md'
				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				overflowX='hidden'
				overflowY='scroll'
			>
				<Box position='relative' bg='rgb(14, 15, 52)'>
					<Text
						p={4}
						textAlign={'center'}
						fontSize={25}
						fontWeight={600}
						color='#fff'
					>
						Career
					</Text>
					<Box
						position={'absolute'}
						right='10'
						top='50%'
						transform={'translateY(-50%)'}
					>
						<Text
							color={'#fff'}
							fontWeight={600}
							bg='gray.600'
							px='15px'
							py='5px'
							borderRadius={'5px'}
							cursor='pointer'
							display={'flex'}
							alignItems='center'
							justifyContent={'center'}
							gap={'5px'}
							_hover={{
								background: 'gray.700',
							}}
							onClick={() => {
								navigate('/dashboard/career/new');
							}}
						>
							<FontAwesomeIcon icon={faPlus} /> New
						</Text>
					</Box>
				</Box>
				<TableContainer>
					<Table variant={'striped'} overflow='scroll' mt={3}>
						<Thead>
							<Tr>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Title
								</Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
							</Tr>
						</Thead>
						<Tbody>
							{dataList.map((item, index) => {
								return (
									<Career
										data={item}
										key={index}
										setState={setState}
									/>
								);
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default Careers;
