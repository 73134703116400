const { default: axios } = require('axios');

const uploadWorkApi = ({
	image,
	heading,
	type,
	description,
	clientName,
	providedServices,
	para1,
	clientTestimonial,
	clientEmployeeName,
	clientDesignation,
}) => {
	return axios.post('https://cftlabs-admin.herokuapp.com/work/upload-work', {
		image: image[0],
		heading,
		type,
		description,
		clientName,
		providedServices: providedServices.split(','),
		para1,
		clientTestimonial,
		clientEmployeeName,
		clientDesignation,
	});
};

export default uploadWorkApi;
