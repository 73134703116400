const uploadImageToCloud = async (imageObject) => {
	const imageLinks = [];

	for (let i = 0; i < imageObject.length; i++) {
		try {
			const file = imageObject[i];
			const formData = new FormData();
			formData.append('file', file);
			formData.append(
				'upload_preset',
				process.env.REACT_APP_UPLOAD_PRESET
			);
			formData.append('cloud_name', process.env.REACT_APP_CLOUD_NAME);
			try {
				let response = await fetch(
					`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/upload`,
					{
						method: 'post',
						body: formData,
					}
				);

				response = await response.json();
				imageLinks.push(response.url);
			} catch (error) {
				console.log('something went wrong');
				console.log(error);
			}
			// imageLinks.push(response.data);
		} catch (error) {
			console.log(error);
		}
	}
	return imageLinks;
};

export default uploadImageToCloud;
