import axios from 'axios';

const createResourceApi = (
	resourceTitle,
	Description,
	image,
	keySentence,
	Description1
) => {
	return axios.post(
		'https://cftlabs-admin.herokuapp.com/resource/upload-resource',
		{
			resourceTitle,
			Description,
			Description1,
			image: image[0],
			keySentence: keySentence.split(','),
		}
	);
};

export default createResourceApi;
