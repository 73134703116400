import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
  return (
    <aside
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        backgroundColor: "#0e0f34",
        display: "inline-block",
        padding: "0 1.5rem",
      }}
    >
      <div
        style={{
          marginTop: "9rem",
          padding: "5px",
          backgroundColor: "#006df7",
          borderRadius: "4px"
        }}
      >
        <FontAwesomeIcon
          icon={faBars}
          style={{ color: "#fff", fontWeight: "bold", fontSize: "1.5rem" }}
        />
      </div>
      <div
        style={{
          marginTop: "5rem"
        }}
      >
      </div>
    </aside>
  );
};
export default Sidebar;
