import axios from 'axios';

const updateWorkApi = ({
	id,
	image,
	heading,
	type,
	description,
	clientName,
	providedServices,
	para1,
	clientTestimonial,
	clientEmployeeName,
	clientDesignation,
}) => {
	console.log('image is : ', image);
	return axios.patch('https://cftlabs-admin.herokuapp.com/work/update-work', {
		id,
		image,
		heading,
		type,
		description,
		clientName,
		providedServices: providedServices.split(','),
		para1,
		clientTestimonial,
		clientEmployeeName,
		clientDesignation,
	});
};

export default updateWorkApi;
