import Header from "./Components/header";
import Sidebar from "./Components/sidebar";
import Inventory from "./Components/inventory";
import "./admin.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Admin = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        document.title = "Admin Panel";
        if(!localStorage.getItem("user-info")){
            navigate("/login");            
        }
    }, []);
    return(
        <>
            <Header />
            <Sidebar />
            <Inventory />
        </>
    )
}
export default Admin;