import axios from 'axios';

const deleteResourceApi = (resourceId) => {
	return axios.delete(
		'https://cftlabs-admin.herokuapp.com/resource/delete-resource',
		{ data: { resourceId } }
	);
};

export default deleteResourceApi;
