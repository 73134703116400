import {
	Td,
	Tr,
	Select,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogContent,
	Button,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import deleteResourceApi from '../../../apis/dashboard/resource/deleteResourceApi';

const Resource = ({ data, setState }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const toast = useToast();

	const handleDelete = async () => {
		setLoading(true);
		try {
			const response = await deleteResourceApi(data._id);
			console.log(response);
			setLoading(false);
			onClose();
			navigate('/dashboard/resource');
			toast({
				title: 'Success',
				description: 'Resource deleted successfully.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
			setState((prev) => !prev);
		} catch (error) {
			console.log('error : ');
			setLoading(false);
		}
	};

	return (
		<>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Delete Customer
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button
								colorScheme='red'
								onClick={handleDelete}
								ml={3}
								isLoading={loading}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<Tr>
				<Td textAlign={'center'}>{data.resourceTitle}</Td>
				<Td textAlign={'center'}>
					<FontAwesomeIcon
						icon={faPenToSquare}
						color='gray'
						cursor={'pointer'}
						onClick={() => {
							console.log('clicked');
							navigate('/dashboard/resource/edit', {
								state: { data: data },
							});
						}}
					/>
				</Td>
				<Td textAlign={'center'}>
					<FontAwesomeIcon
						icon={faTrashCan}
						color='red'
						cursor={'pointer'}
						onClick={() => {
							console.log('clicked');
							onOpen();
						}}
					/>
				</Td>
			</Tr>
		</>
	);
};

export default Resource;
