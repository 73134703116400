import {
	Box,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Table,
	Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import getJobsApi from '../../../apis/dashboard/jobs/getJobsApi';
import Job from './Job';

const Jobs = () => {
	const [jobs, setJobs] = useState([]);
	const [state, setState] = useState(false);

	useEffect(() => {
		const getList = async () => {
			try {
				const res = await getJobsApi();
				setJobs(res.data.responses);
			} catch (error) {
				console.log('error is : ', error);
			}
		};
		getList();
	}, [state]);

	return (
		<>
			<Box
				w={{ base: '100%', lg: '86vw' }}
				h={'98%'}
				bg='white'
				mx={{ base: 1, lg: '10px' }}
				borderRadius='md'
				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				overflowX='hidden'
				overflowY='scroll'
			>
				<Text
					p={4}
					textAlign={'center'}
					fontSize={25}
					fontWeight={600}
					bg='rgb(14, 15, 52)'
					color='#fff'
				>
					Jobs
				</Text>
				<TableContainer>
					<Table variant={'striped'} overflow='scroll' mt={3}>
						<Thead>
							<Tr>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									For
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									First Name
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Last Name
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Phone No.
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Email
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									DOB
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Position Applied
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Experience
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Referral
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Linkedin
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Resume
								</Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
							</Tr>
						</Thead>
						<Tbody>
							{jobs.map((data, index) => {
								return (
									<Job
										key={index}
										data={data}
										changeState={setState}
									/>
								);
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default Jobs;
