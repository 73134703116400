import {
	Td,
	Tr,
	Select,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogContent,
	Button,
	useDisclosure,
	Text,
	useToast,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { useRef, useState } from 'react';
import updateLeadStatus from '../../../apis/dashboard/leads/updateLeadStatus';
import deleteLeadApi from '../../../apis/dashboard/leads/deleteLeadApi';

const Lead = ({ data, setState }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef();
	const [loading, setLoading] = useState(false);
	const toast = useToast();

	const handleDelete = async () => {
		setLoading(true);
		try {
			const response = await deleteLeadApi(data._id);
			console.log(response);
			setLoading(false);
			onClose();
			setState((prev) => !prev);
			toast({
				title: 'Success',
				description: 'Lead is deleted successfully.',
				status: 'success',
				duration: 7000,
				isClosable: true,
			});
		} catch (error) {
			console.log('error');
		}
	};

	const handleStatusChange = async (e) => {
		console.log(e.target.value);
		try {
			const response = await updateLeadStatus(data._id, e.target.value);
			console.log(response);
			toast({
				title: 'Success',
				description: `status is successfully updated to "${e.target.value}".`,
				status: 'success',
				duration: 7000,
				isClosable: true,
			});
		} catch (error) {
			console.log('something went wrong');
		}
	};

	const date = new Date(data.createdAt);

	return (
		<>
			{console.log(data)}
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Delete Customer
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button
								colorScheme='red'
								onClick={handleDelete}
								ml={3}
								isLoading={loading}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<Tr>
				<Td textAlign={'center'}>{data.firstName}</Td>
				<Td textAlign={'center'}>{data.lastName}</Td>
				<Td textAlign={'center'}>{data.phone}</Td>
				<Td textAlign={'center'}>{data.email}</Td>
				<Td textAlign={'center'}>{data.companyName}</Td>
				<Td textAlign={'center'}>{data.companyType}</Td>
				<Td textAlign={'center'}>{data.service}</Td>
				<Td textAlign={'center'}>{data.source}</Td>
				<Td textAlign={'center'}>
					{date.toLocaleDateString()}-{date.toLocaleTimeString()}
				</Td>
				<Td textAlign={'center'}> {data.downloadOne ? '✅' : '-'} </Td>
				<Td textAlign={'center'}>
					{data.downloadOnePlus ? '✅' : '-'}
				</Td>
				<Td textAlign={'center'}>{data.downloadEcomm ? '✅' : '-'} </Td>
				<Td textAlign={'center'}>
					<Text w='250px' whiteSpace={'pre-wrap'}>
						{data.message}
					</Text>
				</Td>
				<Td textAlign={'center'}>
					<Select width={'130px'} onChange={handleStatusChange}>
						<option
							selected={
								data.status === 'Contacted' ? true : false
							}
						>
							Contacted
						</option>
						<option
							selected={
								data.status !== 'Contacted' ? true : false
							}
						>
							pending
						</option>
					</Select>
				</Td>
				<Td textAlign={'center'}>
					<FontAwesomeIcon
						icon={faTrashCan}
						color='red'
						cursor={'pointer'}
						onClick={() => {
							console.log('clicked');
							onOpen();
						}}
					/>
				</Td>
			</Tr>
		</>
	);
};

export default Lead;
