import React, { useEffect, useState } from 'react';
import {
	Box,
	Text,
	Button,
	Icon,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MdWork } from 'react-icons/md';
import { BsCardText, BsFillFileTextFill } from 'react-icons/bs';
import {
	faChevronDown,
	faUser,
	faUserGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FaUserNinja } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Dashboard = () => {
	const navigate = useNavigate();
	const path = useLocation().pathname.split('/')[2];

	console.log('path is ', path);

	const logoutHandler = () => {
		localStorage.clear('user-info');
		navigate('/login');
	};

	return (
		<Box w='100vw' h='100vh' overflowX={'hidden'} bg='#fefefe'>
			{/* heading */}
			<Box
				h='80px'
				display={'flex'}
				justifyContent={'space-between'}
				alignItems={'center'}
				px={10}
				bg='white'
				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				mb='5px'
			>
				<Text h='100px' fontSize={35} fontWeight={600} pl={4} pt={5}>
					CFT Labs
				</Text>
				<Menu>
					<MenuButton
						as={Button}
						rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
					>
						<FontAwesomeIcon icon={faUser} />
					</MenuButton>
					<MenuList>
						<MenuItem>Profile</MenuItem>
						<MenuItem onClick={logoutHandler}>Logout</MenuItem>
					</MenuList>
				</Menu>
			</Box>
			<Box w='100vw' h='calc(100vh - 80px)' display={'flex'}>
				<Box
					h={'98%'}
					w={{ lg: '14vw' }}
					display='flex'
					flexDir={'column'}
					alignItems='center'
					gap={'30px'}
					pl={0}
					pt='10px'
					borderRadius={'sm'}
					boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
				>
					<Text
						py='10px'
						px='20px'
						bg={
							path === '' || path === undefined
								? 'rgb(14, 15, 52)'
								: 'none'
						}
						color={
							path === '' || path === undefined
								? 'white'
								: 'black'
						}
						borderRadius={'md'}
						w='90%'
						textAlign={'center'}
						boxShadow={
							path === '' || path === undefined ? '2xl' : 'none'
						}
						cursor={'pointer'}
						requests
						onClick={() => {
							navigate('/dashboard/');
						}}
						display='flex'
						alignItems={'center'}
						justifyContent='start'
						gap='10px'
					>
						<i className='icofont-contacts'></i>
						<Text
							display={'flex'}
							alignItems='flex-center'
							gap='5px'
						>
							{' '}
							<Text>
								<FontAwesomeIcon
									icon={faUserGroup}
									fontSize={'16px'}
								/>
							</Text>
							Leads
						</Text>
					</Text>
					<Text
						py='10px'
						px='20px'
						bg={path === 'career' ? 'rgb(14, 15, 52)' : 'none'}
						color={path === 'career' ? 'white' : 'black'}
						borderRadius={'md'}
						w='90%'
						textAlign={'center'}
						boxShadow={path === 'career' ? '2xl' : 'none'}
						cursor={'pointer'}
						requests
						onClick={() => {
							navigate('/dashboard/career');
						}}
						display='flex'
						alignItems={'center'}
						justifyContent='start'
						gap='10px'
					>
						<i className='icofont-contacts'></i>
						<Text display={'flex'} alignItems='center' gap='5px'>
							<Icon as={FaUserNinja} fontSize={20} />
							Career
						</Text>
					</Text>
					<Text
						py='10px'
						px='20px'
						bg={path === 'work' ? 'rgb(14, 15, 52)' : 'none'}
						color={path === 'work' ? 'white' : 'black'}
						borderRadius={'md'}
						w='90%'
						textAlign={'center'}
						boxShadow={path === 'work' ? '2xl' : 'none'}
						cursor={'pointer'}
						requests
						onClick={() => {
							navigate('/dashboard/work');
						}}
						display='flex'
						alignItems={'center'}
						justifyContent='start'
						gap='10px'
					>
						<i className='icofont-contacts'></i>
						<Text display={'flex'} alignItems='center' gap='5px'>
							{' '}
							<Icon as={MdWork} fontSize={20} />
							Work
						</Text>
					</Text>
					<Text
						py='10px'
						px='20px'
						bg={path === 'resource' ? 'rgb(14, 15, 52)' : 'none'}
						color={path === 'resource' ? 'white' : 'black'}
						borderRadius={'md'}
						w='90%'
						textAlign={'center'}
						boxShadow={path === 'resource' ? '2xl' : 'none'}
						cursor={'pointer'}
						requests
						onClick={() => {
							navigate('/dashboard/resource');
						}}
						display='flex'
						alignItems={'center'}
						justifyContent='start'
						gap='10px'
					>
						<i className='icofont-contacts'></i>
						<Text display={'flex'} alignItems='center' gap='5px'>
							<Icon as={BsCardText} fontSize={20} />
							Resource
						</Text>
					</Text>
					<Text
						py='10px'
						px='20px'
						bg={path === 'jobs' ? 'rgb(14, 15, 52)' : 'none'}
						color={path === 'jobs' ? 'white' : 'black'}
						borderRadius={'md'}
						w='90%'
						textAlign={'center'}
						boxShadow={path === 'jobs' ? '2xl' : 'none'}
						cursor={'pointer'}
						requests
						onClick={() => {
							navigate('/dashboard/jobs');
						}}
						display='flex'
						alignItems={'center'}
						justifyContent='start'
						gap='10px'
					>
						<i className='icofont-contacts'></i>
						<Text display={'flex'} alignItems='center' gap='5px'>
							<Icon as={BsFillFileTextFill} fontSize={20} />
							Job Applica.
						</Text>
					</Text>
					<Box flexGrow={2}></Box>
				</Box>
				<Outlet />
			</Box>
		</Box>
	);
};

export default Dashboard;
