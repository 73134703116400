import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	Text,
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Td,
	Tbody,
} from '@chakra-ui/react';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Lead from './Lead';
import { useEffect, useState } from 'react';
import getLeadsApi from '../../../apis/dashboard/leads/getLeadsApi';

const Leads = () => {
	const [leads, setLeads] = useState([]);
	const [state, setState] = useState(false);

	useEffect(() => {
		const getlist = async () => {
			try {
				const response = await getLeadsApi();
				setLeads(response.data);
			} catch (error) {
				console.log('something went wrong');
			}
		};
		getlist();
	}, [state]);

	return (
		<>
			<Box
				w={{ base: '100%', lg: '86vw' }}
				h={'98%'}
				bg='white'
				mx={{ base: 1, lg: '10px' }}
				borderRadius='md'
				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				overflowX='hidden'
				overflowY='scroll'
			>
				<Text
					p={4}
					textAlign={'center'}
					fontSize={25}
					fontWeight={600}
					bg='rgb(14, 15, 52)'
					color='#fff'
				>
					Leads
				</Text>
				<TableContainer>
					<Table variant={'striped'} overflow='scroll' mt={3}>
						<Thead>
							<Tr>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									First Name
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Last Name
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Phone No.
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Email
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Company Name
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									inquiry-type
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									service
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									source
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Date / Time
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									One
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									One+
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Ecom
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									message
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									status
								</Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
							</Tr>
						</Thead>
						<Tbody>
							{leads.map((item, index) => {
								return (
									<Lead
										data={item}
										setState={setState}
										key={index}
									/>
								);
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default Leads;
