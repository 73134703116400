import {
	Box,
	Text,
	Input,
	Textarea,
	Button,
	Select,
	useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import updateJobApi from '../../../apis/dashboard/career/updateJobApi';
import ReactQuill from 'react-quill';
import '../../../../node_modules/react-quill/dist/quill.snow.css';

const EditCareer = () => {
	const data = useLocation().state.data;
	const [loading, setLoading] = useState(false);
	const [jobHeading, setJobHeading] = useState(data.jobHeading);
	const [jobType, setJobType] = useState(data.jobType);
	const [jobDescription, setJobDescription] = useState(data.jobDescription);
	const [blogBody, setBlogBody] = useState(data.jobDescription);
	const navigate = useNavigate();
	const toast = useToast();

	const handleBody = (e) => {
		setBlogBody(e);
		setJobDescription(e);
	};

	const handleFormSubmit = async () => {
		setLoading(true);
		try {
			const response = await updateJobApi(
				data._id,
				jobHeading,
				blogBody,
				jobType
			);
			console.log(response);
			setLoading(false);
			navigate('/dashboard/career');
			toast({
				title: 'Success',
				description: 'New job is added successfully.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		} catch (error) {
			setLoading(false);
			navigate('/dashboard/career');
			toast({
				title: 'Error',
				description: error.message,
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
	};

	return (
		<>
			<Box
				w={{ base: '100%', lg: '86vw' }}
				h={'98%'}
				bg='white'
				mx={{ base: 1, lg: '10px' }}
				borderRadius='md'
				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				overflowX='hidden'
				overflowY='scroll'
			>
				<Text
					p={4}
					textAlign={'center'}
					fontSize={25}
					fontWeight={600}
					bg='rgb(14, 15, 52)'
					color='#fff'
				>
					Edit Career
				</Text>
				<Box px='100px' pt='50px'>
					<Box pb='30px'>
						<Text fontSize={20}>Title</Text>
						<Input
							type='text'
							value={jobHeading}
							onChange={(e) => {
								setJobHeading(e.target.value);
							}}
						/>
					</Box>
					<Box pb='30px'>
						<Text fontSize={20}>Type</Text>
						<Select
							onChange={(e) => {
								setJobType(e.target.value);
							}}
						>
							<option
								value=''
								selected={jobType === '' ? true : false}
							></option>
							<option
								value='Internship'
								selected={
									jobType === 'Internship' ? true : false
								}
							>
								Internship
							</option>
							<option
								value='Full-Time'
								selected={
									jobType === 'Full-Time' ? true : false
								}
							>
								Full Time
							</option>
						</Select>
					</Box>
					<Box>
						<Text fontSize={20}>Description</Text>
						<ReactQuill
							placeholder='create new post'
							modules={EditCareer.modules}
							formats={EditCareer.formate}
							onChange={handleBody}
							value={blogBody}
						/>
					</Box>
					<Box
						pt='40px'
						display={'flex'}
						justifyContent='space-between'
					>
						<Button
							colorScheme={'red'}
							onClick={() => {
								navigate('/dashboard/career');
							}}
						>
							Cancel
						</Button>
						<Button
							colorScheme={'green'}
							onClick={handleFormSubmit}
							isLoading={loading}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

EditCareer.formate = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'link',
	'image',
	'video',
	'code-block',
];

export default EditCareer;
