import Login from "./login";
import Admin from "./admin";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Leads from "./pages/Dashboard/leads/Leads";
import Dashboard from "./pages/Dashboard/Dashboard";
import Careers from "./pages/Dashboard/career/Careers";
import EditCareer from "./pages/Dashboard/career/EditCareer";
import NewCareer from "./pages/Dashboard/career/NewCareer";
import Works from "./pages/Dashboard/work/Works";
import EditWork from "./pages/Dashboard/work/EditWork";
import NewWork from "./pages/Dashboard/work/NewWork";
import Resources from "./pages/Dashboard/resources/Resources";
import EditResource from "./pages/Dashboard/resources/EditResource";
import NewResource from "./pages/Dashboard/resources/NewResource";
import Jobs from "./pages/Dashboard/jobs/Jobs";

const App = () => {
	return (
		<Router>
			<Routes>
				<Route
					path="/"
					element={
						localStorage.getItem("user-info") ===
						'{"username":"admin","password":"admin"}' ? (
							<Navigate to="/dashboard" />
						) : (
							<Navigate to="/login" />
						)
					}
				/>
				<Route path="/login" element={<Login />} />

				<Route path="/admin" element={<Admin />} />
				<Route path="/leads" element={<Leads />} />
				<Route path="/dashboard" element={<Dashboard />}>
					<Route index element={<Leads />} />
					<Route path="career">
						<Route index element={<Careers />} />
						<Route path="edit" element={<EditCareer />} />
						<Route path="new" element={<NewCareer />} />
					</Route>
					<Route path="work">
						<Route index element={<Works />} />
						<Route path="new" element={<NewWork />} />
						<Route path="edit" element={<EditWork />} />
					</Route>
					<Route path="resource">
						<Route index element={<Resources />} />
						<Route path="edit" element={<EditResource />} />
						<Route path="new" element={<NewResource />} />
					</Route>
					<Route path="jobs">
						<Route index element={<Jobs />} />
					</Route>
				</Route>
			</Routes>
		</Router>
	);
};
export default App;
