import axios from 'axios';

const deleteJobsApi = (id) => {
	return axios.delete(
		'https://cftlabs-admin.herokuapp.com/job/delete-response',
		{ data: { jobResponseId: id } }
	);
};

export default deleteJobsApi;
