import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import "./admin-header.css"
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.clear("user-info");
    navigate("/login");
  }
  return (
    <Navbar style={{ background: "#fff", borderBottom: "3px solid lightgrey", position: "relative", zIndex: 1 }}>
      <Container fluid>
        <Navbar.Brand>
          <img
            src="https://cftlabs.org/assets/img/favicon.png"
            style={{ height: "30px", width: "30px", marginLeft: "1rem" }}
          />
        </Navbar.Brand>
        <Nav>
          <NavDropdown
            title={
              <FontAwesomeIcon
                icon={faUser}
                style={{
                  color: "#000",
                  border: "2px solid #000",
                  borderRadius: "50%",
                  padding: "2px 2px 0",
                }}
              />
            }
          >
            <NavDropdown.Item>Profile</NavDropdown.Item>
            <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};
export default Header;
