import {
	Td,
	Tr,
	Icon,
	AlertDialog,
	useDisclosure,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
	useToast,
} from '@chakra-ui/react';
import { FaFileDownload } from 'react-icons/fa';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import deleteJobsApi from '../../../apis/dashboard/jobs/deleteJobsApi';

const Job = ({ data, changeState }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef();
	const [loading, setLoading] = useState(false);
	const toast = useToast();

	const handleDelete = async () => {
		setLoading(true);
		try {
			const response = await deleteJobsApi(data._id);
			console.log(response);
			setLoading(false);
			onClose();
			changeState((prev) => !prev);
			toast({
				title: 'Success',
				description: 'Lead is deleted successfully.',
				status: 'success',
				duration: 7000,
				isClosable: true,
			});
		} catch (error) {
			toast({
				title: 'Error',
				description: error.message,
				status: 'error',
				duration: 7000,
				isClosable: true,
			});
		}
	};

	return (
		<>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Delete Customer
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button
								colorScheme='red'
								onClick={handleDelete}
								ml={3}
								isLoading={loading}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<Tr>
				<Td textAlign={'center'}>{data.jobName}</Td>
				<Td textAlign={'center'}>{data.fName}</Td>
				<Td textAlign={'center'}>{data.lName}</Td>
				<Td textAlign={'center'}>{data.mobile}</Td>
				<Td textAlign={'center'}>{data.email}</Td>
				<Td textAlign={'center'}>{data.dob}</Td>
				<Td textAlign={'center'}>{data.positionApplied}</Td>
				<Td textAlign={'center'}>{data.experience}</Td>
				<Td textAlign={'center'}>{data.referral}</Td>
				<Td textAlign={'center'}>{data.linkedIn}</Td>
				<Td textAlign={'center'}>
					<a
						href={data.resume}
						rel='noreferrer'
						download={true}
						target='_blank'
					>
						<Icon
							as={FaFileDownload}
							color='green'
							cursor={'pointer'}
						/>
					</a>
				</Td>
				<Td textAlign={'center'}>
					<FontAwesomeIcon
						icon={faTrashCan}
						color='red'
						cursor={'pointer'}
						onClick={() => {
							console.log('clicked');
							onOpen();
						}}
					/>
				</Td>
			</Tr>
		</>
	);
};

export default Job;
