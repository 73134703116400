import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

const Home = () => {
	return (
		<ChakraProvider>
			<App />
		</ChakraProvider>
	);
};
ReactDOM.render(<Home />, document.querySelector('#root'));
