import {
	Box,
	Text,
	Input,
	Textarea,
	Button,
	Select,
	useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import createJobApi from '../../../apis/dashboard/career/createJobApi';
import ReactQuill from 'react-quill';
import '../../../../node_modules/react-quill/dist/quill.snow.css';

const NewCareer = () => {
	const [jobHeading, setJobHeading] = useState('');
	const [jobDescription, setJobDescription] = useState('');
	const [jobType, setJobType] = useState('');
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const toast = useToast();

	const [blogBody2, setBlogBody2] = useState('');

	const handleBody2 = (e) => {
		console.log(e);
		setBlogBody2(e);
		setJobDescription(e);
	};

	const handleFormSubmit = async () => {
		setLoading(true);

		try {
			const response = await createJobApi({
				jobHeading,
				jobType,
				jobDescription,
			});
			console.log(response);
			setLoading(false);
			navigate('/dashboard/career');
			toast({
				title: 'Success',
				description: 'New job is added successfully.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		} catch (error) {
			toast({
				title: 'Error',
				description: error.message,
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Box
				w={{ base: '100%', lg: '86vw' }}
				h={'98%'}
				bg='white'
				mx={{ base: 1, lg: '10px' }}
				borderRadius='md'
				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				overflowX='hidden'
				overflowY='scroll'
			>
				<Text
					p={4}
					textAlign={'center'}
					fontSize={25}
					fontWeight={600}
					bg='rgb(14, 15, 52)'
					color='#fff'
				>
					Add Career
				</Text>
				<Box px='100px' pt='50px'>
					<Box pb='30px'>
						<Text fontSize={20}>Title</Text>
						<Input
							type='text'
							value={jobHeading}
							onChange={(e) => {
								setJobHeading(e.target.value);
							}}
						/>
					</Box>
					<Box pb='30px'>
						<Text fontSize={20}>Type</Text>
						<Select
							onChange={(e) => {
								setJobType(e.target.value);
							}}
						>
							<option value=''></option>
							<option value='Internship'>Internship</option>
							<option value='Full-Time'>Full Time</option>
						</Select>
					</Box>
					<Box>
						<Text fontSize={20}>Description</Text>
						<ReactQuill
							placeholder='create new post'
							modules={NewCareer.modules}
							formats={NewCareer.formate}
							onChange={handleBody2}
							value={blogBody2}
						/>
						{/* <Textarea
							value={jobDescription}
							onChange={(e) => {
								setJobDescription(e.target.value);
							}}
						/> */}
					</Box>
					<Box
						pt='40px'
						display={'flex'}
						justifyContent='space-between'
					>
						<Button
							colorScheme={'red'}
							onClick={() => {
								navigate('/dashboard/career');
							}}
						>
							Cancel
						</Button>
						<Button
							colorScheme={'green'}
							onClick={handleFormSubmit}
							isLoading={loading}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

NewCareer.formate = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'link',
	'image',
	'video',
	'code-block',
];

export default NewCareer;
