import axios from 'axios';

const updateJobApi = (id, jobHeading, jobDescription, jobType) => {
	console.log('id is ', jobDescription);
	return axios.patch('https://cftlabs-admin.herokuapp.com/job/update-job', {
		id,
		jobHeading,
		jobDescription: String(jobDescription),
		jobType,
	});
};

export default updateJobApi;
