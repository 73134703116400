import {
	Box,
	Text,
	Input,
	Textarea,
	Button,
	useToast,
	Image,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import '../../../../node_modules/react-quill/dist/quill.snow.css';
import uploadImageToCloud from '../../../apis/uploadImageApi';
import updateResourceApi from '../../../apis/dashboard/resource/updateResourceApi';

const EditResource = () => {
	const navigate = useNavigate();
	const data = useLocation().state.data;
	const [uploadedImage, setUploadedImage] = useState([data.image]);
	const [isImageSelected, setIsImageSelected] = useState(
		data.image ? true : false
	);
	const [imageObject, setImageObject] = useState([]);
	const [blogBody, setBlogBody] = useState(data.Description);
	const [blogBody2, setBlogBody2] = useState(data.Description1);
	const [title, setTitle] = useState(data.resourceTitle);
	const [keySentence, setKeySentence] = useState(
		data.keySentence.join(' , ')
	);
	const [isChanged, setIsChanged] = useState(false);
	const [loading, setLoading] = useState(false);

	const [isImageChanged, setIsImageChanged] = useState(false);

	const toast = useToast();

	const imageRef = useRef();

	const handleBody = (e) => {
		console.log(e);
		setBlogBody(e);
	};

	const handleBody2 = (e) => {
		console.log(e);
		setBlogBody2(e);
	};

	const handleImageSubmit = (e) => {
		let fileList = [...e.target.files];

		if (fileList.length + uploadedImage.length > 1) {
			toast({
				title: 'Error',
				description: '1 image are allowed at max',
				status: 'error',
			});
		} else {
			setUploadedImage((prev) => {
				let val = fileList.map((item) => {
					return URL.createObjectURL(item);
				});
				prev.push(...val);
				return [...prev];
			});
			setImageObject((prev) => {
				prev.push(...fileList);
				return [...prev];
			});

			setIsImageSelected(true);
		}
	};

	const handleImageRemoval = (index) => {
		let temp = uploadedImage;
		let temp1 = imageObject;
		temp.splice(index, 1);
		temp1.splice(index, 1);
		setUploadedImage((prev) => [...temp]);
		setImageObject((prev) => [...temp1]);
		if (uploadedImage.length === 0) {
			setIsImageSelected(false);
		}

		setIsChanged(true);

		setIsImageChanged(true);
	};

	const timeToSubmit = async () => {
		setLoading(true);
		let image;

		if (isImageChanged) {
			image = await uploadImageToCloud(imageObject);
			image = image[0];
		} else {
			image = data.image;
		}

		try {
			if (isChanged) {
				console.log('we have to upload to cloud');
				image = await uploadImageToCloud(imageObject);
			} else {
				image = [data.image];
			}

			const response = await updateResourceApi(
				data._id,
				title,
				blogBody,
				image,
				keySentence,
				blogBody2
			);

			console.log(response);
			setLoading(false);
			navigate('/dashboard/resource');
		} catch (error) {
			setLoading(false);
			toast({
				title: 'Error',
				description: error.message,
				status: 'error',
			});
		}
	};

	return (
		<>
			<Box
				w={{ base: '100%', lg: '86vw' }}
				h={'98%'}
				bg='white'
				mx={{ base: 1, lg: '10px' }}
				borderRadius='md'
				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				overflowX='hidden'
				overflowY='scroll'
				pb='100px'
			>
				<Text
					p={4}
					textAlign={'center'}
					fontSize={25}
					fontWeight={600}
					bg='rgb(14, 15, 52)'
					color='#fff'
				>
					Add Resource
				</Text>
				<Box px='100px' pt='50px'>
					<Box pb='30px'>
						<Text fontSize={20}>Title</Text>
						<Input
							type='text'
							value={title}
							onChange={(e) => {
								setTitle(e.target.value);
							}}
						/>
					</Box>
					<Box pb='30px'>
						<Text fontSize={20}>
							Key Sentence [comma seprated ]
						</Text>
						<Input
							type='text'
							value={keySentence}
							onChange={(e) => {
								setKeySentence(e.target.value);
							}}
						/>
					</Box>
					<Box pb='30px'>
						<Text fontSize={20}>Description - 1</Text>
						<ReactQuill
							placeholder='create new post'
							modules={EditResource.modules}
							formats={EditResource.formate}
							onChange={handleBody}
							value={blogBody}
						/>
					</Box>
					<Box pb='30px'>
						<Text fontSize={20}>Description - 2</Text>
						<ReactQuill
							placeholder='create new post'
							modules={EditResource.modules}
							formats={EditResource.formate}
							onChange={handleBody2}
							value={blogBody2}
						/>
					</Box>

					<Box pb='30px'>
						<Text fontSize={20}> Upload Image </Text>
						{!isImageSelected ? (
							<Box
								bg='lightblue'
								display={'inline-block'}
								ml='10px'
								color='gray.600'
								px='10px'
								py='5px'
								borderRadius='md'
								cursor={'pointer'}
								onClick={() => {
									imageRef.current.click();
								}}
							>
								<Input
									type='file'
									display={'none'}
									ref={imageRef}
									accept='image/*'
									onChange={handleImageSubmit}
								/>
								Upload Image
							</Box>
						) : (
							<></>
						)}
						{isImageSelected ? (
							<Box display={'flex'} alignItems='end' gap='10px'>
								{uploadedImage.map((item, index) => {
									return (
										<Box
											position={'relative'}
											display={'inline-flex'}
											bg='lightgray'
											ml='20px'
											mt='20px'
											alignItems={'center'}
											gap={3}
											key={item}
										>
											<Image src={item} height='100px' />

											<Box
												p='10px'
												h='30px'
												w='30px'
												position={'absolute'}
												top={0}
												right={0}
												transform={
													'translate(+50%, -50%)'
												}
												background='#32bac9'
												borderRadius={'full'}
												cursor='pointer'
												color='white'
												onClick={() => {
													handleImageRemoval(index);
												}}
												display='flex'
												alignItems={'center'}
												justifyContent='center'
											>
												<FontAwesomeIcon
													icon={faXmark}
												/>
											</Box>
										</Box>
									);
								})}
							</Box>
						) : (
							<></>
						)}
					</Box>

					<Box
						pt='40px'
						display={'flex'}
						justifyContent='space-between'
					>
						<Button
							colorScheme={'red'}
							onClick={() => {
								navigate('/dashboard/resource');
							}}
						>
							Cancel
						</Button>
						<Button
							colorScheme={'green'}
							onClick={timeToSubmit}
							isLoading={loading}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

EditResource.formate = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'link',
	'image',
	'video',
	'code-block',
];

export default EditResource;
