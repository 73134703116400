import axios from 'axios';

const deleteLeadApi = (id) => {
	console.log('id is ', id);
	return axios.delete(
		'https://cftlabs-admin.herokuapp.com/contact/delete-lead',
		{ data: { id } }
	);
};

export default deleteLeadApi;
