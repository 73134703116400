import axios from 'axios';

const updateResourceApi = (
	id,
	resourceTitle,
	Description,
	image,
	keySentence,
	Description1
) => {
	console.log('id is ', id);
	console.log('title is : ', resourceTitle);
	console.log('des : ', Description);
	console.log('image : ', image);
	console.log('kse : ', keySentence);

	return axios.patch(
		'https://cftlabs-admin.herokuapp.com/resource/update-resource',
		{
			id,
			resourceTitle,
			Description,
			Description1,
			image: image[0],
			keySentence: keySentence.split(','),
		}
	);
};

export default updateResourceApi;
