import axios from 'axios';

const createJobApi = ({ jobHeading, jobDescription, jobType }) => {
	return axios.post('https://cftlabs-admin.herokuapp.com/job/upload-job', {
		jobHeading,
		jobDescription,
		jobType,
	});
};

export default createJobApi;
