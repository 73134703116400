import axios from 'axios';

const updateLeadStatus = (id, status) => {
	console.log('id is : ', id);
	console.log('status is : ', status);
	return axios.patch(
		'https://cftlabs-admin.herokuapp.com/contact/update-status',
		{ id, status }
	);
};

export default updateLeadStatus;
